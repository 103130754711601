import { createResource, Show, Suspense } from "solid-js";
import * as helper from "~/utils/helper_program";
import CTAs from "~/components/Cards/Components/CTAs";
import PreviewText from "~/components/Cards/Components/PreviewText";
import CardShell from "~/components/Cards/CardShell";
import VisualContainer from "~/components/Cards/Components/VisualContainer";
import { getWrapper } from "~/lib/fetcher";

import "./Card.css";
import type { SearchParams } from "~/types/drupal_jsonapi";
import { brandClassName } from "~/utils/format";
import Typologies from "~/components/Cards/Components/Typologies";

export default function CardInfoWindow(props: {
  nid: number;
  searchParams?: SearchParams;
}) {
  const [wrapper] = createResource(() => props.nid, getWrapper);

  return (
    <>
      <Suspense fallback={<CardShell />}>
        <Show when={wrapper()}>
          <article
            class={`node-program-card info-window ${brandClassName(wrapper()!.program.field_brand.field_id)}`}
            data-test={`card-program-${wrapper()!.program.field_program_id}`}
            data-ga-zone="card"
          >
            <VisualContainer wrapper={wrapper()!} />

            <div class="content-program search-map">
              <Typologies
                wrapper={wrapper()!}
                searchParams={props.searchParams}
              />

              <Show when={helper.isPreview(wrapper()!)}>
                <PreviewText type="info-window" />
              </Show>
            </div>

            <CTAs wrapper={wrapper()!} />
          </article>
        </Show>
      </Suspense>
    </>
  );
}
